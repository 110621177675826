import React from "react";

import { Box, Button as BaseButton, CircularProgress, lighten, darken } from "@mui/material";
import PropTypes from "prop-types";

import stylizeComponent from "styles/stylizeComponent";

const StyledButton = stylizeComponent(
  BaseButton,
  ["size2", "textColor"],
  ({ theme, ...props }) => {
    // eslint-disable-next-line
    let padding = props.padding;
    if (!padding) {
      if (props.variant === "outlined") {
        switch (props.size2) {
          case "xs":
            padding = "3px 9px"; // Material small
            break;
          case "s":
            padding = "5px 15px"; // Material medium
            break;
          case "m":
            padding = "7px 21px"; // Material large
            break;
          case "l":
            padding = "11px 24px";
            break;
          default:
            padding = "5px 15px";
            break;
        }
      } else {
        switch (props.size2) {
          case "xs":
            padding = "4px 10px"; // Material small
            break;
          case "s":
            padding = "6px 16px"; // Material medium
            break;
          case "m":
            padding = "8px 22px"; // Material large
            break;
          case "l":
            padding = "12px 25px";
            break;
          default:
            padding = "6px 16px";
            break;
        }
      }
    }

    let textColorValue = null;
    if (props.textColor) {
      const listOfPaletteKeys = props.textColor.split(".");
      textColorValue = theme.palette;
      listOfPaletteKeys.forEach((key) => {
        textColorValue = textColorValue[key];
      });
    }

    let iconFontSize = null;
    switch (props.size2) {
      case "xs":
        iconFontSize = "12px"; // Material small
        break;
      case "s":
        iconFontSize = "14px"; // Material medium
        break;
      case "m":
        iconFontSize = "16px"; // Material large
        break;
      case "l":
        iconFontSize = "18px";
        break;
      default:
        iconFontSize = "14px";
        break;
    }

    let colorMain = null;
    let colorDisabled = null;
    let colorHover = null;
    switch (props.color) {
      case "inherit":
        colorMain = theme.palette.action.disabled;
        colorDisabled = theme.palette.action.disabled;
        colorHover = theme.palette.action.disabled;
        break;
      case "primary":
        colorMain = theme.palette.primary.main;
        colorDisabled = theme.palette.primary.disabled;
        colorHover = theme.palette.primary.hover;
        break;
      default:
        colorMain = theme.palette[props.color].main;
        colorDisabled = lighten(theme.palette[props.color].main, theme.palette.offsetColorVariantDisabled);
        colorHover = darken(theme.palette[props.color].main, theme.palette.offsetColorVariantHover);
        break;
    }

    return {
      "&.MuiButton-root": {
        borderRadius: "5px",
        fontSize: "14px",
        fontWeight: 500,
        padding,
        textAlign: "center",
        width: props.width || "100%",
        ".MuiCircularProgress-root": {
          width: iconFontSize,
          height: iconFontSize,
        },
        minWidth: props.minWidth || null,
      },
      "&.MuiButton-iconSizeSmall": {
        "& > *:first-of-type": {
          fontSize: iconFontSize,
        },
      },
      "&.MuiButton-contained": {
        "&.Mui-disabled": {
          color: theme.palette.getContrastText(colorMain), // In order to fake opacity
          backgroundColor: colorDisabled,
          ".MuiCircularProgress-root": {
            color: theme.palette.getContrastText(colorMain),
          },
        },
        "@media (hover: hover)": {
          "&:hover": {
            color: theme.palette.getContrastText(colorHover),
            backgroundColor: colorHover,
            ".MuiCircularProgress-root": {
              color: theme.palette.getContrastText(colorHover),
            },
          },
        },
      },
      "&.MuiButton-outlined": {
        backgroundColor: theme.palette.customized.buttonOutlinedBackground,
        "&.Mui-disabled": {
          border: "1px solid ".concat(
            theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
          ),
          // opacity: 0.4,
        },
      },
      "&.MuiButton-outlinedBlack": {
        "&.Mui-disabled": {
          opacity: 0.4,
        },
        "@media (hover: hover)": {
          "&:hover": {
            backgroundColor: theme.palette.customized.buttonOutlinedBackground,
            border: `1px solid ${theme.palette.grey.grey2}`,
          },
          border: `1px solid ${theme.palette.grey.grey3}`,
        },
      },
      "&.MuiButton-outlinedPrimary": {
        "&.Mui-disabled": {
          color: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main} 0.5`,
          opacity: 0.4,
          ".MuiCircularProgress-root": {
            color: theme.palette.primary.main,
          },
        },
        "@media (hover: hover)": {
          "&:hover": {
            backgroundColor: theme.palette.customized.buttonOutlinedBackground,
          },
        },
      },
      "&.MuiButton-outlinedSecondary": {
        "&.Mui-disabled": {
          color: theme.palette.secondary.main,
          border: `1px solid ${theme.palette.secondary.main} 0.5`,
          opacity: 0.4,
          ".MuiCircularProgress-root": {
            color: theme.palette.secondary.main,
          },
        },
        "@media (hover: hover)": {
          "&:hover": {
            backgroundColor: theme.palette.customized.buttonOutlinedBackground,
          },
        },
      },
      "&.MuiButton-text": {
        "&.Mui-disabled": {
          opacity: 0.4,
        },
      },
      "&.MuiButton-textPrimary": {
        "&.Mui-disabled": {
          color: theme.palette.primary.main,
          opacity: 0.4,
          ".MuiCircularProgress-root": {
            color: theme.palette.primary.main,
          },
        },
      },
      "&.MuiButton-textSecondary": {
        "&.Mui-disabled": {
          color: theme.palette.secondary.main,
          opacity: 0.4,
          ".MuiCircularProgress-root": {
            color: theme.palette.secondary.main,
          },
        },
      },
      "&.MuiButton-colorInherit": {
        color: textColorValue || "inherit",
        borderColor: "currentColor",
        "&.Mui-disabled": {
          color: "inherit",
          opacity: 0.4,
          ".MuiCircularProgress-root": {
            color: "inherit",
          },
        },
        ".MuiCircularProgress-root": {
          color: textColorValue || "inherit",
        },
      },
      "@media (hover: hover)": {
        "&:hover": {
          backgroundColor: null,
        },
      },
    };
  },
);

/**
 * A standard button with the appropriate style.
 */
const Button = React.forwardRef((
  {
    children,
    color = "primary",
    displayLoader = false,
    endIcon = null,
    loaderPolicy = "leftOfText",
    onClick = null,
    padding = null,
    size = "l",
    startIcon = null,
    textColor = null,
    variant = "contained",
    wrapPolicy = "wrap",
    ...props
  },
  ref,
) => {
  let className = null;
  if (wrapPolicy === "noWrapEllipsis") {
    className = "wz-g-overflow-hidden-text-ellipsis";
  } else if (wrapPolicy === "noWrapScroll") {
    className = "wz-g-overflow-scroll-hidden-bar";
  }

  const loader = <CircularProgress color="inherit" size={null} />;

  return (
    <StyledButton
      ref={ref}
      size="small"
      size2={size}
      onClick={onClick}
      color={color}
      variant={variant}
      disableRipple
      padding={padding}
      textColor={textColor}
      startIcon={loaderPolicy === "startIcon" && displayLoader ? loader : startIcon}
      endIcon={loaderPolicy === "endIcon" && displayLoader ? loader : endIcon}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      {loaderPolicy === "leftOfText" && displayLoader && (
        <>
          {loader}
          &nbsp;&nbsp;
        </>
      )}
      {loaderPolicy === "replaceText" && displayLoader && (
        <Box sx={{ lineHeight: "0", margin: "0rem 1rem" }}>
          {loader}
        </Box>
      )}
      {!(loaderPolicy === "replaceText" && displayLoader) && (
        <Box className={className}>
          {children}
        </Box>
      )}
      {loaderPolicy === "rightOfText" && displayLoader && (
        <>
          &nbsp;&nbsp;
          {loader}
        </>
      )}
    </StyledButton>
  );
});

Button.propTypes = {
  /**
   * The children, content of the button
   */
  children: PropTypes.any.isRequired,
  /**
   * The color of the button (similar to Material UI Button).
   */
  color: PropTypes.oneOf(["black", "inherit", "primary", "secondary", "success", "error", "info", "warning"]),
  /**
   * Whether we display the loader on the button to show to the user the action is processing.
   */
  displayLoader: PropTypes.bool,
  /**
   * Element placed before the children.
   */
  endIcon: PropTypes.node,
  /**
   * Whether we display the loader on the button to show to the user the action is processing.
   */
  loaderPolicy: PropTypes.oneOf(["leftOfText", "rightOfText", "replaceText", "startIcon", "endIcon"]),
  /**
   * The callback to trigger when we click on the button.
   */
  onClick: PropTypes.func,
  /**
   * The custom padding. It will override the padding computed by 'size' props.
   */
  padding: PropTypes.string,
  /**
   * The size of the button.
   */
  size: PropTypes.oneOf(["xs", "s", "m", "l"]),
  /**
   * Element placed before the children.
   */
  startIcon: PropTypes.node,
  /**
   * Whether we want override the text color (mainly used for background issues for 'inherit' color).
   * The string must be the short palette key according to the Mui sx syntax.
   */
  textColor: PropTypes.string,
  /**
   * The variant appearance of the button (similar to Material UI Button).
   */
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  /**
   * The behaviour policy for overflow / wrapping.
   * If the content is too large, we can either display text ellipsis, cut the text and scroll or wrap the content.
   */
  wrapPolicy: PropTypes.oneOf(["noWrapEllipsis", "noWrapScroll", "wrap"]),
};

export default Button;
