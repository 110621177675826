import { queryParams, removeHeader } from "libs/api/interceptors";

export default function shops(client, config) {
  const path = `/widgets/${config.widgetUUID}/shops`;
  return {
    get: (id) => client.request({
      version: "v2",
      endpoint: `${path}/${id}`,
      method: "GET",
      cache: "no-store",
      interceptors: [
        // This is to call the API without any invalid credentials. Since this endpoint is open.
        removeHeader("Authorization"),
      ],
    }),
    list: (queryParamsInput) => client.request({
      version: "v2",
      endpoint: `${path}`,
      method: "GET",
      interceptors: [
        // This is to call the API without any invalid credentials. Since this endpoint is open.
        removeHeader("Authorization"),
        Object.values(queryParamsInput)?.length ? queryParams(queryParamsInput) : null,
      ].filter((el) => !!el),
    }),
  };
}
