import { useEffect } from "react";

import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    const timeout = setTimeout(() => window.scrollTo(0, -1), 25);

    return () => {
      clearTimeout(timeout);
    };
  }, [pathname]);

  return null;
};

export default ScrollToTop;
