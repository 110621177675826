import PropTypes from "prop-types";

import useAuthentication from "../../hooks/useAuthentication";

/**
 * This component is the base wrapper to render or not the children depending on the authentication state / context.
 * If the authentication rules (props) are not fulfilled, then we trigger a specific action passed in props.
 */
const Authentication = ({
  authenticatedAuthorized = false,
  notAuthenticatedAuthorized = false,
  onNotAuthorized = null,
  children = null,
}) => {
  const auth = useAuthentication();

  if ((!auth.isAuthenticated && !notAuthenticatedAuthorized) || (auth.isAuthenticated && !authenticatedAuthorized)) {
    return onNotAuthorized ? onNotAuthorized() : null;
  }

  return children;
};

Authentication.propTypes = {
  /**
   * Whether the user is authorized if he is authenticated.
   */
  authenticatedAuthorized: PropTypes.bool,
  /**
   * Whether the user is authorized if he is not authenticated.
   */
  notAuthenticatedAuthorized: PropTypes.bool,
  /**
   * The action to trigger when the authentication rule is not fulfilled.
   */
  onNotAuthorized: PropTypes.func,
  /**
   * The components to render when the authentication rules defined are fulfilled.
   */
  children: PropTypes.node,
};

export default Authentication;
