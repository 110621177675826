import React from "react";

import { Grid, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

/**
 * This component is used to display a loader when a page is loading or an API call is processing for example.
 */
const Loader = ({ color = "secondary", margin = null, size = "2rem" }) => (
  <Grid container justifyContent="center">
    <Grid item xs={12} textAlign="center" margin={margin}>
      <CircularProgress color={color} size={size} />
    </Grid>
  </Grid>
);

Loader.propTypes = {
  /**
   * The color of the loader.
   */
  color: PropTypes.string,
  /**
   * The margin to add in around the component.
   */
  margin: PropTypes.string,

  /**
   * The size of the circular loader.
   */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Loader;
