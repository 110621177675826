/* eslint-disable import/prefer-default-export */

/**
 * Return whether the value is not null and not undefined. It's especially used for boolean and numeric value
 * in order to not ignore it when it's set as 'false' or '0' in conditions / ternaries.
 * @param {any} value The value to check.
 * @return {boolean} Whether the value is neither null nor undefined.
 */
export const isNeitherNullNorUndefined = (value) => ![null, undefined].includes(value);

/**
 * Compute a string from a list of words, with a separator and a 'and' word before the last occurrence.
 * @param {Array.<string>} stringList The list of word to concat
 * @param {string} andWord The word to use for 'and' (depending on the language)
 * @param {string} separator The separator to use between the words
 * @param {boolean} useAndWord Whether or not we want to use the 'and' word or only the separator even for the last word
 * @return {string} The sentence part or string after the words' concatenation.
 */
export const wordsListToString = (stringList, andWord, separator, useAndWord) => {
  if (!stringList?.length) {
    return "";
  }
  if (stringList?.length === 1) {
    return stringList[0];
  }
  const wordSeparator = andWord || ", ";
  const shouldUseAndWord = !!useAndWord || true;
  return shouldUseAndWord
    ? `${stringList.slice(0, -1).join(`${separator}`)} ${andWord} ${stringList.pop()}`
    : `${stringList.join(`${wordSeparator} `)}`;
};

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window.atob(base64).split("").map((c) => `%${ (`00${ c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(""),
  );

  return JSON.parse(jsonPayload);
};
