import React from "react";

import { createTheme, responsiveFontSizes, ThemeProvider as MuiThemeProvider } from "@mui/material";
import PropTypes from "prop-types";

import theme from "styles/theme";

/**
 * The theme provider to use to wrap the widget and the application.
 */
const ThemeProvider = ({
  children,
  constants = null,
  mode = "light",
  themeToMerge = {},
}) => {
  const defaultTheme = constants ? theme(constants, mode) : {};
  const finalTheme = responsiveFontSizes(createTheme({ ...defaultTheme, ...themeToMerge }));

  const widgetBackground = finalTheme?.palette?.customized?.widgetBackground;

  React.useEffect(
    () => {
      const rootElement = document.getElementById("weezpay-widget-body");
      if (widgetBackground && rootElement) {
        rootElement.style.backgroundColor = widgetBackground;
      } else if (rootElement) {
        rootElement.style.backgroundColor = null;
      }
    },
    [widgetBackground],
  );

  return (
    <MuiThemeProvider theme={finalTheme}>
      {children}
    </MuiThemeProvider>
  );
};

ThemeProvider.propTypes = {
  /**
   * The wrapped components that will have access to the final computed theme.
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired,
  /**
   * The object with all the constants to incorporate and use to compute the theme.
   */
  constants: PropTypes.object,
  /**
   * The theme mode to display.
   */
  mode: PropTypes.oneOf(["light", "dark"]),
  /**
   * An optional theme to merge to the computed one.
   */
  themeToMerge: PropTypes.object,
};

export default ThemeProvider;
