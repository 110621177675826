import React from "react";

import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import OrganizationIdContext from "./OrganizationIdContext";

const OrganizationIdContextProvider = ({ children = null }) => {
  const { organizationId } = useParams();

  return (
    <OrganizationIdContext.Provider
      value={organizationId.replace("O", "")}
    >
      {children}
    </OrganizationIdContext.Provider>
  );
};

OrganizationIdContextProvider.propTypes = {
  children: PropTypes.node,
};

export default OrganizationIdContextProvider;
