import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

import I18nLocaleSelector from "components/I18nLocaleSelector";

/**
 * A component wrapper to display the embedded text with the title style.
 */
const Title = ({ children, shouldDisplayLocaleSelector = false, I18nLocaleSelectorProps = {} }) => (
  <Box marginBottom="10px">
    <Grid container justifyContent="space-between" wrap="nowrap">
      <Grid item>
        <Typography variant="title1">{children}</Typography>
      </Grid>
      {shouldDisplayLocaleSelector && (
        <Grid item>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <I18nLocaleSelector {...I18nLocaleSelectorProps} />
        </Grid>
      )}
    </Grid>
  </Box>
);

Title.propTypes = {
  /**
   * The text/element to embed with the title style.
   */
  children: PropTypes.any.isRequired,
  /**
   * Whether we want to display the locale selector.
   */
  shouldDisplayLocaleSelector: PropTypes.bool,
  /**
   * The props to pass to the LocalSelector component.
   */
  I18nLocaleSelectorProps: PropTypes.shape(I18nLocaleSelector.propTypes),
};

export default Title;
