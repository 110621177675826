import React from "react";

import PropTypes from "prop-types";

import Auth from "api/resources/Auth";
import AutoTopUps from "api/resources/AutoTopUps";
import Carts from "api/resources/Carts";
import Donations from "api/resources/Donations";
import FastTopUps from "api/resources/FastTopUps";
import History from "api/resources/History";
import Orders from "api/resources/Orders";
import PaymentReceipt from "api/resources/PaymentReceipt";
import RawReceipts from "api/resources/RawReceipts";
import Receipts from "api/resources/Receipts";
import Refunds from "api/resources/Refunds";
import Scenarios from "api/resources/Scenarios";
import Shops from "api/resources/Shops";
import Tags from "api/resources/Tags";
import TaxReceipts from "api/resources/TaxReceipts";
import Timeslots from "api/resources/Timeslots";
import TopUps from "api/resources/TopUps";
import Users from "api/resources/Users";
import Wallets from "api/resources/Wallets";
import Widgets from "api/resources/Widgets";
import config from "config";
import useOrganizationId from "hooks/useOrganizationId";
import useWidgetUUID from "hooks/useWidgetUUID";
import { APIProvider, Client } from "libs/api";
import { headers } from "libs/api/interceptors";
import { useAuthentication } from "libs/authentication";
import { useI18n } from "libs/i18n";

const WeezPayAPIProvider = ({ children = null }) => {
  const authentication = useAuthentication();
  const i18n = useI18n();
  const organizationId = useOrganizationId();
  const widgetUUID = useWidgetUUID();

  const weezPayAPI = React.useMemo(
    () => {
      const requestInterceptors = [];
      const sessionId = authentication?.authInfo?.sessionId;
      const token = authentication?.authInfo?.token;

      if (sessionId) {
        requestInterceptors.push(headers({ Authorization: `Session ${sessionId}` }));
      } else if (token) {
        requestInterceptors.push(headers({ Authorization: `Bearer ${token}` }));
      }

      if (i18n.locale) {
        requestInterceptors.push(headers({ "Accept-language": i18n.locale }));
      }

      const apiConfig = {
        organizationId,
        widgetUUID,
        authentication,
        requestInterceptors,
        host: config.url,
      };
      const apiClient = new Client(apiConfig);

      return {
        config: apiConfig,
        client: apiClient,
        auth: Auth(apiClient, apiConfig),
        autotopups: AutoTopUps(apiClient, apiConfig),
        carts: Carts(apiClient, apiConfig),
        donations: Donations(apiClient, apiConfig),
        fastTopUps: FastTopUps(apiClient, apiConfig),
        history: History(apiClient, apiConfig),
        orders: Orders(apiClient, apiConfig),
        paymentReceipt: PaymentReceipt(apiClient, apiConfig),
        rawReceipts: RawReceipts(apiClient, apiConfig),
        receipts: Receipts(apiClient, apiConfig),
        refunds: Refunds(apiClient, apiConfig),
        scenarios: Scenarios(apiClient, apiConfig),
        shops: Shops(apiClient, apiConfig),
        tags: Tags(apiClient, apiConfig),
        taxreceipts: TaxReceipts(apiClient, apiConfig),
        timeslots: Timeslots(apiClient, apiConfig),
        topUps: TopUps(apiClient, apiConfig),
        users: Users(apiClient, apiConfig),
        wallets: Wallets(apiClient, apiConfig),
        widgets: Widgets(apiClient, apiConfig),
      };
    },
    [authentication, i18n.locale, organizationId, widgetUUID],
  );

  return (
    <APIProvider value={{ WeezPay: weezPayAPI }}>
      {children}
    </APIProvider>
  );
};

WeezPayAPIProvider.propTypes = {
  /**
   * The rest of the application to wrap to provide the WeezPay API context.
   */
  children: PropTypes.node,
};

export default WeezPayAPIProvider;
