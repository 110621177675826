import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

import Button from "components/Button";
import { I18n } from "libs/i18n";

const ErrorCard = ({
  shouldDisplayButton = true,
  onClick = null,
  buttonLabel = <I18n id="global-cta-try-again" />,
  errorTitleLabel = <I18n id="global-error-500-title" />,
  errorSubtitleLabel = <I18n id="global-error-500-subtitle" />,
}) => {
  const onClickValue = React.useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      } else {
        window.location.reload();
      }
    },
    [onClick],
  );

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item>
            <CancelIcon className="wz-g-mui-icon-md" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} marginTop="0.75rem">
        <Typography component="p" variant="title1" textAlign="center">
          {errorTitleLabel}
        </Typography>
        <Typography textAlign="center" marginTop="0.25rem">
          {errorSubtitleLabel}
        </Typography>
      </Grid>
      {shouldDisplayButton && (
        <Grid item xs={12} marginTop="1rem">
          <Grid container justifyContent="center">
            <Grid item>
              <Button onClick={onClickValue}>
                {buttonLabel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

ErrorCard.propTypes = {
  /**
   * Whether we have to display the action button or not.
   */
  shouldDisplayButton: PropTypes.bool,
  /**
   * The function to trigger when the user click on the action button.
   */
  onClick: PropTypes.func,
  /**
   * The label of the action button.
   */
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * The label of the error (title)
   */
  errorTitleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * The label of the error (subtitle)
   */
  errorSubtitleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ErrorCard;
