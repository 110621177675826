import queryString from "query-string";

function queryParams(data) {
  return (query) => {
    // eslint-disable-next-line no-shadow
    const { url, query: queryParams, fragmentIdentifier } = queryString.parseUrl(
      query.endpoint,
      { parseFragmentIdentifier: true },
    );
    return {
      ...query,
      endpoint: queryString.stringifyUrl(
        { url, fragmentIdentifier, query: { ...queryParams, ...data } },
      ),
    };
  };
}

function host(name, basePath) {
  return (query) => ({ ...query, endpoint: `${name}${basePath}${query.endpoint}` });
}

function headers(data) {
  return (query) => ({ ...query, headers: { ...query.headers, ...data } });
}

function removeHeader(key) {
  return (query) => {
    const queryHeaders = query.headers || {};
    if (Object.prototype.hasOwnProperty.call(queryHeaders, key)) {
      delete queryHeaders[key];
    }
    return { ...query, headers: queryHeaders };
  };
}

function bodyRender() {
  return (query) => ({
    ...query,
    headers: { ...query.headers, ...(query.body ? { "Content-Type": "application/json" } : {}) },
    body: query.body ? JSON.stringify(query.body) : query.body,
  });
}

function jsonRender() {
  return (response) => {
    const contentType = response.headers.get("content-type");
    const isJson = contentType && contentType.indexOf("application/json") !== -1;
    return isJson ? response.json() : response;
  };
}

function saveAsFile(filename, filetype) {
  return async (response) => {
    const blob = await response.blob();
    if (response.ok) {
      // Create a fake link to download the file
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.style = "display: none";

      const data = new Blob([blob], {
        type: filetype || "application/pdf",
      });

      // Create the pdf from the url
      const urlPDF = window.URL.createObjectURL(data);

      // Set the data of the pdf inside the anchor
      link.href = urlPDF;
      link.download = filename;
      link.click();

      // Clean objects when user has downloaded his file
      window.URL.revokeObjectURL(urlPDF);
      document.body.removeChild(link);
    }
  };
}

function disconnect(config) {
  return (response) => {
    if (response.status === 403 || response.status === 401) {
      if (config?.redirection_url) {
        window.location.replace(config?.redirection_url);
      } else {
        config?.authentication?.logout?.();
      }
    }
    return response;
  };
}

export {
  host,
  headers,
  removeHeader,
  bodyRender,
  jsonRender,
  queryParams,
  disconnect,
  saveAsFile,
};
