import React from "react";

import { Grid } from "@mui/material";
import PropTypes from "prop-types";

import Card from "components/Card";
import ErrorCard from "components/ErrorCard";
import LocaleSelector from "components/LocaleSelector";
import { I18n, useI18n } from "libs/i18n";

const ErrorPage = ({ errorType = "404" }) => {
  const {
    isLocaleLocked: i18nIsLocaleLocked,
    allowedLocales: i18nAllowedLocales,
    locale: i18nLocale,
    setLocale: i18nSetLocale,
  } = useI18n();

  return (
    <Grid container spacing={2}>
      {!i18nIsLocaleLocked && i18nAllowedLocales?.length > 1 && (
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="flex-end">
            <Grid item>
              <LocaleSelector
                availableLocales={i18nAllowedLocales}
                selectedLocale={i18nLocale}
                onChange={i18nSetLocale}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          {errorType === "404" && (
            <ErrorCard
              shouldDisplayButton={false}
              errorTitleLabel={<I18n id="global-error-404-title" />}
              errorSubtitleLabel={<I18n id="global-error-404-subtitle" />}
            />
          )}
          {errorType === "500" && (
            <ErrorCard
              shouldDisplayButton={false}
            />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

ErrorPage.propTypes = {
  /**
   * The type of error page.
   */
  errorType: PropTypes.oneOf(["404", "500"]),
};

export default ErrorPage;
