import React from "react";

import { writeStorage, deleteFromStorage, useLocalStorage } from "@rehooks/local-storage";
import PropTypes from "prop-types";
import queryString from "query-string";

import Auth from "api/resources/Auth";
import config from "config";
import { Client } from "libs/api";
import { parseJwt } from "utils/stringUtils";

import AuthenticationContext from "./AuthenticationContext";

const JWT_TOKEN_AUTH = "Bearer";

/**
 * The context provider of AuthenticationContext
 */
const AuthenticationContextProvider = ({ organizationId, widgetUUID = null, children }) => {
  /* ------------ Initialization of the imports, the variables and the states ------------ */
  const [authInfo, setAuthInfo] = React.useState(null);

  const AUTH_INFO_KEY_LEGACY = `auth-${organizationId}`;
  const AUTH_INFO_KEY = `auth-${organizationId}-${widgetUUID}`;

  /* ------------ Utils to read the authentication / authorization info ------------ */
  // The authentication information and credentials given by the local storage.
  let authInfoFromLocalStorage = null;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [authInfoLocalStorage] = useLocalStorage(AUTH_INFO_KEY, null);
    authInfoFromLocalStorage = authInfoLocalStorage;
  } catch (e) {
    /* eslint-disable no-console */
    console.log("Impossible to use localStorage to read");
  }

  // The authentication information and credentials given by the URL.
  const authInfoFromUrl = React.useMemo(
    () => {
      const parsedHash = queryString.parse(window.location.hash);
      const parsedSearch = queryString.parse(window.location.search);
      // eslint-disable-next-line no-shadow, no-nested-ternary
      const token = "token" in parsedHash || "token" in parsedSearch
        ? parsedHash.token || parsedSearch.token
        : "?token" in parsedHash
          ? parsedHash["?token"]
          : null;
      // eslint-disable-next-line no-shadow, no-nested-ternary
      const sessionid = "sessionid" in parsedSearch || "sessionId" in parsedSearch
        ? parsedSearch.sessionid || parsedSearch.sessionId
        // eslint-disable-next-line no-nested-ternary
        : "?sessionid" in parsedSearch
          ? parsedSearch["?sessionid"]
          : "?sessionId" in parsedSearch
            ? parsedSearch["?sessionId"]
            : null;
      if (token) {
        return { provider: JWT_TOKEN_AUTH, token };
      }
      if (sessionid) {
        return { provider: "basic", sessionId: sessionid };
      }
      return null;
    },
    [],
  );

  /* ------------ Utils to write the authentication / authorization info ------------ */
  const writeAuthInfoInLocalStorage = React.useCallback(
    (authInfoToWrite) => {
      try {
        writeStorage(AUTH_INFO_KEY_LEGACY, authInfoToWrite);
        writeStorage(AUTH_INFO_KEY, authInfoToWrite);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Impossible to use localStorage to write");
      }
    },
    [AUTH_INFO_KEY_LEGACY, AUTH_INFO_KEY],
  );

  /* ------------ Utils to remove the authentication / authorization info ------------ */
  const deleteAuthInfoFromLocalStorage = React.useCallback(
    () => {
      try {
        deleteFromStorage(AUTH_INFO_KEY_LEGACY);
        deleteFromStorage(AUTH_INFO_KEY);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Impossible to use localStorage to delete");
      }
    },
    [AUTH_INFO_KEY_LEGACY, AUTH_INFO_KEY],
  );

  /* ------------ Utils and business functions to manage authentication / authorization ------------ */
  const isAuthenticated = React.useMemo(
    () => Boolean(authInfo),
    [authInfo],
  );

  const clear = React.useCallback(
    () => {
      setAuthInfo(null);
      deleteAuthInfoFromLocalStorage();
    },
    [deleteAuthInfoFromLocalStorage],
  );

  const login = React.useCallback(
    async (newAuthInfo, redirect, params) => {
      const newAuthInfoWithConfig = { config: {}, ...newAuthInfo };

      if (newAuthInfo.provider === JWT_TOKEN_AUTH) {
        const iss = parseJwt(newAuthInfo.token)?.iss;

        if (iss) {
          const apiConfig = {
            organizationId,
            widgetUUID,
            authentication: {},
            requestInterceptors: [],
            host: config.url,
          };
          const authProviders = await Auth(new Client(apiConfig), apiConfig).list();
          const matchedProvider = authProviders.filter((authProvider) => authProvider.provider === iss);

          newAuthInfoWithConfig.config = matchedProvider?.[0]?.config || {};
        }
      }

      setAuthInfo(newAuthInfoWithConfig);
      writeAuthInfoInLocalStorage(newAuthInfoWithConfig);
      if (redirect) {
        redirect(params);
      }
    },
    [writeAuthInfoInLocalStorage, organizationId, widgetUUID],
  );

  const logout = React.useCallback(
    () => {
      clear();
    },
    [clear],
  );

  /* ------------ Authentication / authorization side effect ------------ */
  // Take the information provided by the URL and try to log in with it whatever the authentication status.
  // Consequently, we will override the current authentication if any.
  React.useEffect(
    () => {
      const redirectAfterLogin = () => {
        const url = new URL(window.location);
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, null, url);
      };
      if (authInfoFromUrl) {
        login(authInfoFromUrl, redirectAfterLogin, null);
      }
    },
    [authInfoFromUrl, login],
  );

  // Take the information provided by the localstorage if there is still information on it
  // and if no authentication context yet.
  // If this information was out of date, the back-end will return a 403 that must be handled
  // to enforce the logging out and the local storage flush.
  React.useEffect(
    () => {
      if (authInfoFromLocalStorage && !isAuthenticated && !authInfoFromUrl) {
        login(authInfoFromLocalStorage, null, null);
      }
    },
    [authInfoFromLocalStorage, authInfoFromUrl, isAuthenticated, login],
  );

  const value = React.useMemo(
    () => ({
      authInfo,
      isAuthenticated,
      login,
      logout,
    }),
    [authInfo, isAuthenticated, login, logout],
  );

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationContextProvider.propTypes = {
  /**
   * The ID of the organization to scope the authentication.
   */
  organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * The UUID of the widget to scope the authentication.
   */
  widgetUUID: PropTypes.string,
  /**
   * The wrapped components that will have access to the context.
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired,
};

export default AuthenticationContextProvider;
