import React from "react";

import PropTypes from "prop-types";

import I18nLocaleSelector from "components/I18nLocaleSelector";

import Container from "./components/Container";
import Subtitle from "./components/Subtitle";
import Title from "./components/Title";

/**
 * A card layout component to group title, subtitle and content.
 * This card is the base card used in the whole cashless application.
 */
const Card = ({
  backgroundColor = null,
  borderColor = null,
  children = null,
  color = "default",
  displayBorder = true,
  error = null,
  isSelected = false,
  margin = null,
  padding = null,
  shouldDisplayLocaleSelector = null,
  subtitle = null,
  sx = {},
  title = null,
  I18nLocaleSelectorProps = {},
}) => (
  <Container
    padding={padding}
    margin={margin}
    color={color}
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    displayBorder={displayBorder}
    isSelected={isSelected}
    sx={sx}
  >
    {error}
    {title && (
      <Title
        shouldDisplayLocaleSelector={shouldDisplayLocaleSelector}
        I18nLocaleSelectorProps={I18nLocaleSelectorProps}
      >
        {title}
      </Title>
    )}
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
    {children}
  </Container>
);

Card.propTypes = {
  /**
   * The background color to override (instead of the default one computed by 'color').
   */
  backgroundColor: PropTypes.string,
  /**
   * The border color to override (instead of the default one computed by 'color'.
   */
  borderColor: PropTypes.string,
  /**
   * The main content of the component.
   */
  children: PropTypes.any,
  /**
   * Color theme of the component.
   */
  color: PropTypes.oneOf(["default", "primary"]),
  /**
   * Whether we don't want to remove the borders of the card.
   */
  displayBorder: PropTypes.bool,
  /**
   * The error content of the component.
   */
  error: PropTypes.any,
  /**
   * Whether the component is selected in order to display the UI / color of a selected block.
   */
  isSelected: PropTypes.bool,
  /**
   * If we want to override the default margins of the container.
   */
  margin: PropTypes.string,
  /**
   * If we want to override or not the default paddings of the container.
   */
  padding: PropTypes.string,
  /**
   * Whether we want to display the locale selector.
   */
  shouldDisplayLocaleSelector: PropTypes.bool,
  /**
   * The description/subtitle of the component.
   */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * The free style props (from 'mui').
   */
  sx: PropTypes.object,
  /**
   * The main text/title of the component.
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * The props to pass to the LocalSelector component.
   */
  I18nLocaleSelectorProps: PropTypes.shape(I18nLocaleSelector.propTypes),
};

export default Card;
